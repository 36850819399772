<template>
  <section class="tutorial-buttons positioner">
    <section
      v-if="tutorialState.buttons.showMovementButtons && !gameSettingsState.oneFinger"
    >
      <arrow-animation
        v-if="tutorialState.showArrowAnimation.left"
        position="bottom"
        style="left:0; bottom: 0%; transform-origin: left bottom; position: fixed; width: 572px; height: 472px;"
      />
      <movement-sides
        :disabled="false"
        ignore-safe-zones
        type="paddle"
      />
      <arrow-animation
        v-if="tutorialState.showArrowAnimation.right"
        position="bottom"
        style="right:0; bottom: 0%; transform-origin: right bottom; position: fixed; width: 572px; height: 472px;"
      />
    </section>
    <section
      v-if="tutorialState.buttons.showMovementButtons && gameSettingsState.oneFinger"
    >
      <section
        v-if="!gameSettingsState.isLeft"
        class="flex justify-between fixed"
      >
        <arrow-animation
          position="bottom"
          style="right:0; bottom: 0%; transform-origin: right bottom; position: fixed; width: 572px; height: 472px;"
        />
        <section />
        <section style="position: fixed; bottom: 0; right: 0">
          <movement-buttons
            type="paddle"
            class="bottom-right"
            :orientation="'horizontal'"
          />
        </section>
      </section>
      <section
        v-else
        class="flex justify-between"
      >
        <arrow-animation
          position="bottom"
          style="left:0; bottom: 0%; transform-origin: left bottom; position: fixed; width: 572px; height: 472px;"
        />
        <section style="position: fixed; bottom: 0; left: 0">
          <movement-buttons
            type="paddle"
            class="bottom-left"
            :orientation="'horizontal'"
          />
        </section>
      </section>
    </section>
  </section>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import {
  WindowAspect,
  ArrowAnimation,
  MovementButtons,
  MovementSides,
  gameSettingsState
} from '@powerplay/core-minigames-ui-ssm'
import { tutorialState } from '@/stores'
import { disciplinePhasesManager } from '@/app/phases/DisciplinePhasesManager'

export default defineComponent({
  name: 'TutorialButtons',
  components: {
    ArrowAnimation,
    MovementButtons,
    MovementSides
  },
  mixins: [WindowAspect],
  data() {

    return {
      tasks: [],
      blockedEndAction: false,
      wasTypewriting: false,
      tutorialState: tutorialState(),
      gameSettingsState: gameSettingsState(),
    }

  },
  computed: {
    transformCoef() {

      return { transform: `scale(${this.scaleCoef})` }

    },
    showLeftArrow() {

      if (!disciplinePhasesManager.phaseStart.ended) return this.gameSettingsState.isLeft
      return true

    },
    showRightArrow() {

      if (!disciplinePhasesManager.phaseStart.ended) return !this.gameSettingsState.isLeft
      return true

    }
  },
})
</script>

<!-- TU nemoze byt scoped, pokial bude riesene .small-action-button-wrapper v tomto file -->
<style lang="less">

.nomobile-small-buttons-tutorial {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 375px;
    height: 375px;

    .bar {
        position: relative;
    }

    .arrow-wrapper {
        transform-origin: 50% 0%;
    }
    .bottom-right {
    transform-origin: bottom right;
    }
    .bottom-left {
    transform-origin: bottom left;
    }
}

.tutorial {

    .tutorial-buttons.positioner {
        position: absolute;
        bottom: 0;
        width: 100%;
        pointer-events: none;
    }

    .flex {
        display: flex;
        justify-content: space-between;
    }

    .row {
        flex-direction: row;
    }

    .row-reverse {
        flex-direction: row-reverse;
    }

    .buttons-set-wrapper {
        position: relative;
        width: 580px;
        height: 580px;
    }

    .buttons-position {
        position: absolute;
        top: -60px;
        left: 0;
        width: 100%;
        height: 200px;
    }

    .tutorial-anne {
        bottom: 0;
    }

    .sub-positions {

        .bar-right {
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;
            width: 50%;
            height: 200px;
            position: absolute;
            right: 0;

            .small-action-button-wrapper {
                pointer-events: auto;
                justify-content: flex-start;
                width: 100%;
                height: 100%;
                align-items: flex-end;
                padding: 0 20px;
            }
        }
        .bar-left {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            width: 50%;
            height: 200px;
            position: absolute;
            left: 0;

            .small-action-button-wrapper {
                pointer-events: auto;
                justify-content: flex-end;
                width: 100%;
                height: 100%;
                align-items: flex-end;
                padding: 0 20px;
            }
        }
    }
    .bottom-right {
        transform-origin: bottom right;
    }
    .bottom-left {
        transform-origin: bottom left;
    }
    .top-right {
        transform-origin: top right;
    }

    .tutorial-buttons {
        position: fixed;
        width: 100vw;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        height: 100vh;
        pointer-events: auto;
        transform: none;

        .movement-sides-container {
          .power-mobile-button-ssm {
            pointer-events: auto;

            .button-element {
              .image {
                height: 280px;
                width: 280px;
              }
            }
          }
        }
        .flex {
          display: flex;
        }

        .justify-between {
          justify-content: space-between;
        }

        .relative {
          position: relative;
        }

        .buttons-position {
          position: absolute;
          top: -60px;
          left: 0;
          width: 100%;
          height: 200px;
        }

        .action-sides-container {
            height: 580px;
            left: 0;
            right: 0;
            display: flex;
            justify-content: space-between;

            &.disabled {
                opacity: 0.7;
            }

            .power-mobile-button{
                .button-element{
                    .image{
                        height: 100px;
                        width: 100px;
                        background-size: cover;
                        left: 50%;
                        margin-left: -50px;
                    }
                }
            }

            .left-side{
                display: flex;
                position: fixed;
                left: 0;
                bottom: 0;
                transform-origin: bottom left;

                .left-side-button {
                    .button-element {
                        .image {
                            margin-left: -30px;
                        }
                    }
                }
            }

            .right-side{
                display: flex;
                position: fixed;
                right: 0;
                bottom: 0;
                transform-origin: bottom right;

                .right-side-button {
                    .button-element {
                        .image {
                            margin-left: 20px;
                        }
                    }
                }
            }

        }
        .bottom-right {
          transform-origin: bottom right;
        }
        .bottom-left {
          transform-origin: bottom left;
        }
      }
}
</style>
