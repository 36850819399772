import { notesConfig } from '@/app/config'
import { player } from './Player'
import { THREE } from '@powerplay/core-minigames'

/**
 * Manager pre input notes efekty
 */
export class PlayerNotesEffects {

  // timestamp
  public timestamp = -1

  // Material pre efekt ciary
  private materialLineEffect!: THREE.ShaderMaterial

  // Material pre efekt kruhu
  private materialNoteEffect!: THREE.ShaderMaterial

  // mesh pre efekt ciary
  private meshLineEffect!: THREE.Mesh

  // mesh pre efekt kruhu
  private meshNoteEffect!: THREE.Mesh

  // ci je aktivny efect kruhu (nie globalne ale pre ucely zobrazovania efektu)
  private activeNoteEffect = false

  /**
   * Nastavenie efektu ciary
   * @param material - material
   * @param geometry - geometria
   * @param position - pozicia
   */
  public setUpLineEffect(material: THREE.ShaderMaterial, geometry: THREE.PlaneGeometry, position: THREE.Vector3): void {

    this.materialLineEffect = material
    this.meshLineEffect = new THREE.Mesh(geometry, this.materialLineEffect)
    player.athleteObject.add(this.meshLineEffect)
    this.meshLineEffect.position.copy(position)
    this.meshLineEffect.scale.x = 0.2
    this.meshLineEffect.rotateX(Math.PI / 2)
    this.meshLineEffect.renderOrder = 9
    this.meshLineEffect.matrixAutoUpdate = true
    this.setVisibilityLineEffect(false)

  }

  /**
   * Nastavenie efektu kruhu
   * @param material - material
   * @param geometry - geometria
   * @param meshHelper - helper pre mesh (kvoli rotacii aj pozicii)
   */
  public setUpNoteEffect(material: THREE.ShaderMaterial, geometry: THREE.SphereGeometry, meshHelper: THREE.Mesh): void {

    this.materialNoteEffect = material
    this.meshNoteEffect = new THREE.Mesh(geometry, material)
    this.meshNoteEffect.visible = false
    this.meshNoteEffect.position.copy(meshHelper.position)
    this.meshNoteEffect.rotation.copy(meshHelper.rotation)
    this.meshNoteEffect.renderOrder = 10
    player.athleteObject.add(this.meshNoteEffect)

  }

  /**
   * Nastavenie viditelnost efektu ciary
   * @param visibility - True, ak ma byt viditelne
   */
  public setVisibilityLineEffect(visibility: boolean): void {

    this.meshLineEffect.visible = visibility

  }

  /**
   * Nastavenie viditelnost efektu kruhu
   * @param visibility - True, ak ma byt viditelne
   */
  public setVisibilityNoteEffect(visibility: boolean): void {

    this.meshNoteEffect.visible = visibility

  }

  /**
   * Zobrazenie efektu kruhu
   * @param elapsedTime - Elapsed time
   */
  private showNoteEffect(elapsedTime: number): void {

    if (this.activeNoteEffect) return

    this.activeNoteEffect = true
    this.timestamp = elapsedTime

  }

  /**
   * Ci je dana farba spravna pre spustenie efektu
   * @param color - farba
   * @returns True, ak ano
   */
  private isColorForEffect(color: THREE.Color): boolean {

    return color === notesConfig.colors.perfect

  }

  /**
   * Skontrolovanie a nastavenie viditelnosti efektu kruhu a jeho spustenie ak treba
   * @param color - farba
   * @param elapsedTime - elapsed time
   */
  public checkVisibilityAndNoteEffect(color: THREE.Color, elapsedTime: number): void {

    this.setVisibilityNoteEffect(this.isColorForEffect(color))
    if (this.meshNoteEffect.visible) {

      this.showNoteEffect(elapsedTime)

    } else {

      this.activeNoteEffect = false

    }

  }

  /**
   * Aktualizovanie zakladnych veci
   * @param color - farba
   */
  public update(color: THREE.Color): void {

    if (this.isColorForEffect(color)) {

      this.meshLineEffect.scale.x = 1
      this.materialLineEffect.uniforms.noteColor.value = notesConfig.colors.perfect
      this.materialLineEffect.uniforms.opacity.value = 1

    } else {

      this.meshLineEffect.scale.x = 0.2
      this.materialLineEffect.uniforms.noteColor.value = notesConfig.colors.white
      this.materialLineEffect.uniforms.opacity.value = 0.3

    }

  }

  /**
   * Akutalizacia shaderov efektov
   * @param newValue - Hodnota casu
   */
  public updateEffects(newValue: number): void {

    if (this.timestamp === -1) return

    const diff = newValue - this.timestamp
    this.materialNoteEffect.uniforms.uTime.value = diff

    if (this.materialLineEffect.uniforms.noteColor.value === notesConfig.colors.perfect) {

      this.materialLineEffect.uniforms.uTime.value = diff

      const percent = diff < 0.4 ?
        diff / 0.4 :
        1 - ((diff - 0.4) / 0.4)

      this.materialLineEffect.uniforms.noteStrength.value = percent * 0.6

    } else {

      this.materialLineEffect.uniforms.noteStrength.value = 0

    }


  }

  /**
   * Resetovanie
   */
  public reset(): void {

    this.meshLineEffect.scale.x = 0.2
    this.materialLineEffect.uniforms.noteColor.value = notesConfig.colors.white
    this.materialLineEffect.opacity = 0.3

  }

}