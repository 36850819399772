import {
  MainCore,
  game,
  timeManager,
  CameraTypes,
  settings,
  SettingsTypes,
  gameStats,
  CustomEvents,
  THREE,
  AudioManager,
  CameraStates,
  cameraManager,
  requestManager,
  TimesTypes,
  fpsManager,
  appWSM2021Config,
  modes,
  AppWSM2021DifficultyTypes,
  playersConfig,
  PlayersSecondResultTypes,
  playersManager,
  corePhasesManager,
  CorePhases
} from '@powerplay/core-minigames'
import {
  audioConfig,
  batchingConfig,
  modelsConfig,
  texturesConfig,
  debugConfig,
  cameraConfig,
  gameConfig,
  trainingConfig,
  translatesReplacements,
  lungeConfig
} from './config'
import {
  TexturesNames,
  MaterialsNames,
  DisciplinePhases,
  CustomGameEvents
} from './types'
import { worldEnv } from './entities/env/WorldEnv'
import { player } from './entities/athlete/player'
import { inputsManager } from './InputsManager'
import { disciplinePhasesManager } from './phases/DisciplinePhasesManager'
import { appWSM2021AllDifficultiesConfig } from './config/appWSM2021AllDifficultiesConfig'
import { materialsConfig } from './config/materialsConfig'
import { trainingTasks } from './modes/training/TrainingTasks'
import { stateManager } from './StateManager'
import { tutorialFlow } from './modes/tutorial/TutorialFlow'
import { pathAssetsKayak } from '@/globals/globalvariables'
import { opponentsManager } from './entities/athlete/opponent/OpponentsManager'
import { linesManager } from './entities/env/LinesManager'
import { wind } from './entities/athlete/Wind'
import * as Sentry from '@sentry/vue'
import { endManager } from './EndManager'
import { finishCamera } from './phases/FinishPhase/FinishCamera'
import {
  debugState,
  trainingState,
  tutorialState
} from '@/stores'
import { loadingState } from '@powerplay/core-minigames-ui-ssm'
import { audioHelper } from './audioHelper/AudioHelper'

/**
 * Hlavna trieda pre disciplinu
 */
export class Main {

  /** Hlavna trieda z CORE */
  private mainCore: MainCore

  // Aktualna pozicia kamery
  private actualCameraPosition = new THREE.Vector3()

  // Aktualna rotacia kamery
  private actualCameraQuaternion = new THREE.Quaternion()

  /** Pause prveho tutorialu */
  private pausedFirstTutorial = false

  /** Clock timer */
  private clock = new THREE.Clock()

  /**
   * Konstruktor
   */
  public constructor() {

    this.addListeners()
    // pripravenie konfigov pre WSM 2021 - musime kontrolvat takto kvoli typescriptu
    if (modes.isAppWSM2021()) {

      appWSM2021Config.data = appWSM2021AllDifficultiesConfig[
        modes.mode as unknown as AppWSM2021DifficultyTypes // small TS hack :)
      ]

    }

    /*
     * Nastavenie players konfigov, aby sa dobre zoradovali a mali dobre empty vysledky
     * nemusime zatial nic nastavovat, lebo berieme default hodnoty z konfigu
     */
    this.setPlayersConfig()

    // musime este nastavit, aby nebolo intro v treningu
    corePhasesManager.showIntro = !modes.isTrainingMode()

    // spustime CORE veci a pokial vsetko je v pohode, pusti sa INIT metoda
    this.mainCore = new MainCore(
      {
        meshesCastShadows: materialsConfig[MaterialsNames.athlete].meshesArray || [],
        meshesAnisotropy: [],
        materials: materialsConfig,
        callbacks: {
          inputs: {
            callbackLeft: inputsManager.onKeyLeft,
            callbackRight: inputsManager.onKeyRight,
            callbackUp: inputsManager.onKeyUp,
            callbackDown: inputsManager.onKeyDown,
            callbackAction: inputsManager.onKeyAction,
            callbackAction2: inputsManager.onKeyAction2,
            callbackAction3: inputsManager.onKeyAction3,
            callbackExit: inputsManager.onKeyExit,
            callbackPrepareVideo: inputsManager.onKeyPrepareVideo
          },
          setSpecialDataFromInitRequest: this.setSpecialDataFromInitRequest,
          createAssets: this.createAssets,
          beforeGameStart: this.beforeGameStart,
          updateBeforePhysics: this.updateBeforePhysics,
          updateAfterPhysics: this.updateAfterPhysics,
          updateAnimations: this.updateAnimations
        },
        batching: batchingConfig,
        debugConfig,
        numberOfAttempts: gameConfig.numberOfAttempts,
        inputSchema: 'biathlon'
      },
      translatesReplacements,
      {
        textures: texturesConfig,
        models: modelsConfig,
        audio: audioConfig
      }
    )

    this.initialSetup()

  }

  /**
   * Metoda na overenie a zobrazenie FPS
   */
  private checkFpsRequest(): void {

    if (stateManager.getFpsStarted()) {

      const settingsQuality = settings.getSetting(SettingsTypes.quality)
      const fpsData = {
        averageFps: fpsManager.getAverageFpsByQuality(settingsQuality),
        actualFps: fpsManager.getActualFpsByQuality(settingsQuality),
        actualAverageFps: fpsManager.getActualAverageFps()
      }
      stateManager.setFpsData(fpsData)

    }

  }

  /**
   * Pridanie listenerov
   */
  private addListeners() {

    window.addEventListener(CustomEvents.readyForDisciplineInit, this.init)
    window.addEventListener(CustomEvents.loadingProgress, this.loadingProgress)
    // nastavime pocuvanie na zaciatok disciplinovej fazy z CORE
    window.addEventListener(
      CustomEvents.startDisciplinePhase,
      disciplinePhasesManager.setStartPhase
    )

  }

  /**
   * Inicializacny setup
   */
  private initialSetup() {

    const localEnv = Number(import.meta.env.VITE_APP_LOCAL) === 1
    this.mainCore.setIsLocalEnv(localEnv)
    game.setIsLocalEnv(localEnv)
    game.setCallbackCustomUpdateQuality(this.onUpdateQuality)

    // lokalne si davame ID discipliny, aby sme nemuseli menit v GET parametroch stale
    if (localEnv) requestManager.disciplineID = 15

    AudioManager.PATH_ASSETS = pathAssetsKayak
    disciplinePhasesManager.create()

    /*
     * listener na zistenie appky, ze sme v background mode a mame dat pauzu, aby sme setrili
     * prostriedky a aby nehrali zvuky
     */
    window.addEventListener(CustomEvents.toggleBackgroundMode, () => {

      tutorialState().settings = true

    }, false)

  }

  /**
   * Vratenie ignorovanych nazvov textur
   * @returns Pole nazvov ignorovanych textur
   */
  private getIgnoredTexturesNames(): string[] {

    const allRaceTextures = [
      TexturesNames.athleteRaceBlackMan,
      TexturesNames.athleteRaceBlackWoman,
      TexturesNames.athleteRaceMulattoMan,
      TexturesNames.athleteRaceMulattoWoman,
      TexturesNames.athleteRaceWhiteMan,
      TexturesNames.athleteRaceWhiteWoman
    ]

    const usedTextures: string[] = []

    // pridame hraca
    const playerInfo = playersManager.getPlayer()
    usedTextures.push(`${playerInfo.sex}/${TexturesNames.athleteRacePrefix}${playerInfo.race}`)

    // pridame superov, ak su
    opponentsManager.getOpponentsIds().forEach((uuid) => {

      const opponentInfo = playersManager.getPlayerById(uuid)
      usedTextures.push(`${opponentInfo?.sex}/${TexturesNames.athleteRacePrefix}${opponentInfo?.race}`)

    })

    // vysledok bude rozdiel dvoch poli
    return allRaceTextures.filter(x => !usedTextures.includes(x))

  }

  /**
   * Inicializacia main core
   */
  public init = (): void => {

    // musime nastavit prefix pre meshe, aby nam nekolidovali meshe zo skyboxu s inymi
    game.prefixMeshesGroupEnabled = true

    game.shadowsManager.setShadowPlaneResolution(50, 50)

    opponentsManager.rewriteOpponentsData()
    this.mainCore.init(
      undefined,
      undefined,
      undefined,
      undefined,
      opponentsManager.getOpponentsIds(),
      this.getIgnoredTexturesNames(),
      TexturesNames.athleteRacePrefix
    )

    const tweenSettingsForCameraStates = modes.isTrainingMode() ?
      trainingConfig.tweenSettingsForCameraStates :
      cameraConfig.tweenSettingsForCameraStates

    this.mainCore.setTweenSettingsForStates(tweenSettingsForCameraStates)

    cameraManager.changeBaseRenderSettings(0.1, 15000)
    trainingTasks.initTraining()
    timeManager.setActive(TimesTypes.total, true)

  }

  /**
   * Nastavenie konfigu pre hracov
   */
  private setPlayersConfig(): void {

    playersConfig.secondResultType = PlayersSecondResultTypes.numberArray
    playersManager.setCompareSkillBeforeTimestamp()

  }

  /**
   * Zobrazenie progresu loadingu
   */
  private loadingProgress = (): void => {

    gameStats.setNextLoadingPart()

    loadingState().loadingProgress = gameStats.getLoadingProgress()

  }

  /**
   * Nastavenie specialnych dat z init requestu
   * @param data - Specialne data
   */
  private setSpecialDataFromInitRequest = (data: unknown): void => {

    console.log(data)

    Sentry.setContext('minigame', {
      id: requestManager.MINIGAME_START_ID,
      idUser: player.uuid
    })

  }

  /**
   * Nastavenie assetov
   */
  private createAssets = (): void => {

    linesManager.init()

    // musime si najskor urcit, kto bude na ktorych poziciach zacinat
    disciplinePhasesManager.setStartPositionsForPlayers()

    wind.setValue()
    player.create(disciplinePhasesManager.dataForPlayersStartPositions[playersManager.getPlayer().uuid])
    opponentsManager.create(disciplinePhasesManager.dataForPlayersStartPositions)
    player.setNotes()

    worldEnv.create(this.clock)

    this.setUpDebug()

    // tiene
    game.shadowsManager.attachPlaneToObject(player.athleteObject)

    /*
     * TEMP
     * game.scene.fog = new THREE.Fog( 0xcccccc, 20, 400 )
     */

  }

  /**
   * Ak sa zmeni kvalita, vykonaju sa nasledujuce veci, pre optimalizaciu performance
   * @param settingValue - Hodnota kvality
   */
  public onUpdateQuality = (settingValue: number): void => {

    // hybanie vlajok
    worldEnv.movingFlagsEnabled = settingValue >= 7

    // hybanie skyboxu
    worldEnv.skybox.movingEnabled = settingValue >= 5

    // nastavenie transparentnosti vodyv a inych veci podla kvality z nastaveni
    worldEnv.water.setSettingsByQuality(settingValue)

  }

  /**
   * puts singletons into window object
   */
  private setUpDebug(): void {

    if (!Number(import.meta.env.VITE_APP_LOCAL)) return

    const debug = {
      worldEnv,
      inputsManager,
      player,
      opponentsManager,
      disciplinePhasesManager,
      cameraManager: cameraManager,
      setHUDVisibility: () => ( debugState().isHudActive = true),
      pauseGame: () => {

        if (game.paused) game.resumeGame()
        else game.pauseGame()

      },
      scene: game.scene,
      game,
      THREE,
      linesManager,
      finishCamera,
      changeSun: (newValue: number) => {

        worldEnv.skybox.updateSunPosition(newValue)

      }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).debug = debug

  }

  /**
   * Nastavenie alebo spustenie veci pred startom hry
   */
  private beforeGameStart = (): void => {

    stateManager.setBeforeGameStartPhase()
    endManager.setWorldRecord()
    finishCamera.create()

    // listener na dobehnutie hraca do ciela
    window.addEventListener(CustomGameEvents.playerFinished, () => {

      opponentsManager.setDnfIfNotReachedFinish()

    })

  }

  /**
   * Spustenie veci v update pred vykonanim fyziky
   */
  private updateBeforePhysics = (): void => {

    if (
      !corePhasesManager.isActivePhaseByType(CorePhases.intro) &&
      !corePhasesManager.isActivePhaseByType(CorePhases.discipline)
    ) return

    disciplinePhasesManager.update()
    player.updateBeforePhysics(disciplinePhasesManager.actualPhase)
    opponentsManager.updateBeforePhysics()
    this.checkFpsRequest()

    if (gameConfig.debugLungeOffset) {

      this.debugLungeOffset(
        player
          .worldEnvLinesManager.playerPath?.getPointAt(player.worldEnvLinesManager.getLastPercent() +
          player.worldEnvLinesManager.lungeOffset +
          lungeConfig.lungeOffsetMin * player.worldEnvLinesManager.oneMeterInPercent),
        player.uuid
      )

    }

  }

  /**
   * Zobrazenie debug gul za hracmi, pokial siaha slipstream
   * @param position - pozicia konca slipstream
   * @param uuid - uuid atleta
   * @returns void
   */
  private debugLungeOffset(position: THREE.Vector3 | undefined, uuid: string): void {

    if (!position) return
    const name = `debug-${uuid}`

    const sphere = game.scene.getObjectByName(name)

    if (sphere) {

      sphere.position.set(position.x, position.y + gameConfig.yPlayerCorrection, position.z)
      return

    }

    const geometrySphere = new THREE.SphereGeometry(0.2, 0.2, 0.2)
    const materialSphere = new THREE.MeshBasicMaterial({ color: Math.random() * 0xffffff })
    const meshSphere = new THREE.Mesh(geometrySphere, materialSphere)
    meshSphere.position.set(position.x, position.y + gameConfig.yPlayerCorrection, position.z)
    meshSphere.name = name
    game.scene.add(meshSphere)

  }

  /**
   * Spustenie veci v update po vykonani fyziky
   */
  private updateAfterPhysics = (delta: number): void => {

    if (
      !corePhasesManager.isActivePhaseByType(CorePhases.intro) &&
      !corePhasesManager.isActivePhaseByType(CorePhases.intro2) &&
      !corePhasesManager.isActivePhaseByType(CorePhases.discipline)
    ) return

    if (requestManager.isFirstTrainingTutorial() && !this.pausedFirstTutorial) {

      this.pausedFirstTutorial = true
      console.log(requestManager.TUTORIAL_ID)
      trainingState().firstTutorialMessage = true
      game.pauseGame()
      return

    }
    player.updateAfterPhysics()
    opponentsManager.updateAfterPhysics()

    this.actualCameraPosition.copy(player.getPosition())
    if (
      !finishCamera.isActive &&
      (disciplinePhasesManager.phaseRunning.cameraFromSide || disciplinePhasesManager.phaseRunning.speedLockActive)
    ) {

      // dame este offset dopredu, aby bol na predku lode
      this.actualCameraPosition.z += lungeConfig.cameraLookAtOffsetZ

    }
    if (
      disciplinePhasesManager.actualPhase > DisciplinePhases.start &&
      disciplinePhasesManager.actualPhase < DisciplinePhases.finish
    ) {

      this.actualCameraQuaternion.slerp(
        player.getQuaternion(),
        gameConfig.cameraQuaternionLerp
      )

    } else {

      this.actualCameraQuaternion.copy(player.getQuaternion())

    }

    finishCamera.update()

    const instalerp = [DisciplinePhases.start, DisciplinePhases.preStart].includes(disciplinePhasesManager.actualPhase)

    cameraManager.move(
      this.actualCameraPosition,
      this.actualCameraQuaternion,
      delta,
      [/* hill.hillMesh */],
      cameraConfig.distanceFromGround,
      (
        cameraManager.isThisCameraState(CameraStates.discipline) ||
        cameraManager.isThisCameraState(CameraStates.disciplineOutro) ||
        disciplinePhasesManager.phaseRunning.speedLockActive ||
        disciplinePhasesManager.phaseRunning.cameraFromSide
      ),
      {
        lerpCoef: instalerp ? 1 : 0.1,
        newQuat: player.getQuaternion().clone()
      }
    )

    stateManager.setUpdateTimeState()

    if (modes.isTutorial()) tutorialFlow.update()

    worldEnv.update()
    audioHelper.update()

  }

  /**
   * Spustenie vykonavania vsetkych animacii
   * @param delta - Delta
   */
  private updateAnimations = (delta: number): void => {

    worldEnv.updateWithAnimations(this.clock.getElapsedTime())

    if (
      !corePhasesManager.isActivePhaseByType(CorePhases.intro) &&
      !corePhasesManager.isActivePhaseByType(CorePhases.discipline)
    ) return

    player.updateAnimations(delta)
    opponentsManager.updateAnimations(delta)

  }

  /**
   * Zmena kamery na debug, ak by sme potrebvalo
   */
  public changeCameraToDebug = (): void => {

    cameraManager.setCamera(CameraTypes.debug)

  }

}
