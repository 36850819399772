import {
  game,
  THREE,
  CSS2DRenderer,
  CSS2DObject,
  cameraManager,
  gsap,
  glowNotesVertexShader,
  glowNotesFragmentShader,
  glowLinesVertexShader,
  glowLinesFragmentShader
} from '@powerplay/core-minigames'
import { player } from './Player'
import { notesConfig } from '@/app/config'
import { WorldEnvLinesCreator } from '../../env/WorldEnvLinesCreator'
import {
  DisciplinePhases,
  NoteColors,
  Sides,
  TexturesNames
} from '@/app/types'
import { disciplinePhasesManager } from '@/app/phases/DisciplinePhasesManager'
import { gameSettingsState } from '@powerplay/core-minigames-ui-ssm'
import { PlayerNotesEffects } from './PlayerNotesEffects'

/**
 * Manager pre input notes
 */
export class PlayerNotesManager {

  /** lave kruznice */
  private leftOuterNotes: THREE.Mesh[] = []
  /** lave kruhy */
  private leftInnerNotes: THREE.Mesh[] = []
  /** prave kruznice */
  private rightOuterNotes: THREE.Mesh[] = []
  /** prave kruhy */
  private rightInnerNotes: THREE.Mesh[] = []

  private leftNotesEffects = new PlayerNotesEffects()
  private rightNotesEffects = new PlayerNotesEffects()

  /** pozicie na ciare */
  private leftPoints: THREE.Vector3[] = []
  private rightPoints: THREE.Vector3[] = []

  /** ci je viditelne */
  private visible = true

  /** farby */
  private leftColor = notesConfig.colors.default
  private rightColor = notesConfig.colors.default

  /** renderer */
  private labelRenderer = new CSS2DRenderer()

  /** labels */
  private leftLabels: CSS2DObject[] = []
  private rightLabels: CSS2DObject[] = []

  /** timeline pre kruhy outer */
  private timelineOuterNotes!: gsap.core.Timeline

  private clock!: THREE.Clock

  /**
   * Konstruktor
   */
  public constructor() {

    const { linePoints } = notesConfig

    this.leftPoints = [
      new THREE.Vector3(-linePoints[0].x, linePoints[0].y, linePoints[0].z),
      new THREE.Vector3(-linePoints[1].x, linePoints[1].y, linePoints[1].z)
    ]
    this.rightPoints = [
      new THREE.Vector3(linePoints[0].x, linePoints[0].y, linePoints[0].z),
      new THREE.Vector3(linePoints[1].x, linePoints[1].y, linePoints[1].z)
    ]

  }

  /**
   * Setup
   */
  public setUp(): void {

    const noteOuter = game.getMesh('notes_K1_Note_Outer')
    const noteInner = game.getMesh('notes_K1_Note_Inner')
    const { lineOffset } = notesConfig
    const point1 = WorldEnvLinesCreator.getPointInBetweenByPerc(
      this.leftPoints[0],
      this.leftPoints[1],
      0.5
    )
    const leftOuterNote = noteOuter.clone()
    leftOuterNote.name += '_cloned'
    leftOuterNote.renderOrder = 4
    const noteOuterMaterial = noteOuter.material as THREE.MeshBasicMaterial
    leftOuterNote.material = noteOuterMaterial.clone()
    leftOuterNote.rotation.set(-0.05, 0, 0.05)
    leftOuterNote.matrixAutoUpdate = true
    leftOuterNote.position.set(-lineOffset.x + point1.x, lineOffset.y, lineOffset.z + point1.z)
    leftOuterNote.layers.enableAll()

    const point2 = WorldEnvLinesCreator.getPointInBetweenByPerc(
      this.rightPoints[0],
      this.rightPoints[1],
      0.5
    )
    const rightOuterNote = noteOuter
    rightOuterNote.material = noteOuterMaterial.clone()
    rightOuterNote.renderOrder = 4
    rightOuterNote.rotation.set(-0.05, 0, -0.05)
    rightOuterNote.matrixAutoUpdate = true
    rightOuterNote.position.set(lineOffset.x + point2.x, lineOffset.y, lineOffset.z + point2.z)
    rightOuterNote.layers.enableAll()

    const leftOuterNoteMaterial = leftOuterNote.material as THREE.MeshBasicMaterial
    leftOuterNoteMaterial.color.set(notesConfig.colors.white)
    const rightOuterNoteMaterial = rightOuterNote.material as THREE.MeshBasicMaterial
    rightOuterNoteMaterial.color.set(notesConfig.colors.white)

    this.leftOuterNotes.push(leftOuterNote)
    this.rightOuterNotes.push(rightOuterNote)

    for (let i = 0; i < 2; i++) {

      const leftInnerNote = noteInner.clone()
      const noteInnerMaterial = noteOuter.material as THREE.MeshBasicMaterial
      leftInnerNote.material = noteInnerMaterial.clone()
      leftInnerNote.rotation.set(-0.05, 0, 0.05)
      leftInnerNote.matrixAutoUpdate = true
      leftInnerNote.position.set(-lineOffset.x, lineOffset.y, lineOffset.z)
      leftInnerNote.renderOrder = 5

      const rightInnerNote = noteInner.clone()
      rightInnerNote.material = noteInnerMaterial.clone()
      rightInnerNote.rotation.set(-0.05, 0, -0.05)
      rightInnerNote.matrixAutoUpdate = true
      rightInnerNote.position.set(lineOffset.x + point1.x, lineOffset.y, lineOffset.z)
      rightInnerNote.renderOrder = 5

      const leftInnerNoteMaterial = leftInnerNote.material as THREE.MeshBasicMaterial
      leftInnerNoteMaterial.color.set(notesConfig.colors.default)
      const rightInnerNoteMaterial = rightInnerNote.material as THREE.MeshBasicMaterial
      rightInnerNoteMaterial.color.set(notesConfig.colors.default)

      this.leftInnerNotes.push(leftInnerNote)
      this.rightInnerNotes.push(rightInnerNote)

    }

    this.setUpGlowNotes()
    this.setUpGlowLines()

    player.athleteObject.add(...this.leftInnerNotes)
    player.athleteObject.add(...this.rightInnerNotes)
    player.athleteObject.add(...this.leftOuterNotes)
    player.athleteObject.add(...this.rightOuterNotes)

    player.playerNotesManager.setVisibilityOfAll(false)

    this.prepareLabels()
    this.prepareBottomHud()
    this.prepareStartArrow()

    this.labelRenderer.render(game.scene, cameraManager.getMainCamera())

  }

  public setClock(clock: THREE.Clock): void {

    this.clock = clock

  }

  private setUpGlowLines(): void {

    const geometry = new THREE.PlaneGeometry(0.1, 10, 4, 100)
    const material = new THREE.ShaderMaterial({
      uniforms: {
        uTexture1: {
          value: game.texturesToUse.main.get(TexturesNames.gradients)
        },
        uTime: {
          value: 0
        },
        noteStrength: {
          value: 0
        },
        timeFactor: {
          value: 1
        },
        sizeFactor: {
          value: new THREE.Vector2(2, 0.3)
        },
        frequency: {
          value: 3
        },
        noteColor: {
          value: notesConfig.colors.white
        },
        opacity: {
          value: 0.3
        }

      },
      vertexShader: glowLinesVertexShader,
      fragmentShader: glowLinesFragmentShader,
      transparent: true,
      blending: THREE.AdditiveBlending,
      side: THREE.DoubleSide,
      depthWrite: false,
    })

    const { lineOffset } = notesConfig

    const zOffset = -(this.leftPoints[0].distanceTo(this.leftPoints[1]) / 2 + 0.28)

    const positionLeft = new THREE.Vector3(-lineOffset.x, lineOffset.y - 0.015, lineOffset.z + zOffset)
    this.leftNotesEffects.setUpLineEffect(material.clone(), geometry, positionLeft)
    const positionRight = new THREE.Vector3(lineOffset.x, lineOffset.y - 0.015, lineOffset.z + zOffset)
    this.rightNotesEffects.setUpLineEffect(material, geometry, positionRight)

  }

  /**
   * Priprava veci pre glow efekt na kruhoch
   */
  private setUpGlowNotes(): void {

    // nastavenie textury
    const texture = game.texturesToUse.main.get(TexturesNames.gradients)
    if (texture) {

      texture.minFilter = THREE.LinearFilter
      texture.wrapT = THREE.ClampToEdgeWrapping
      texture.wrapS = THREE.RepeatWrapping
      texture.magFilter = THREE.LinearFilter

    }

    const geometry = new THREE.SphereGeometry(2.0, 24, 10, 0, 2 * Math.PI, 2.4, 0.25)
    const material = new THREE.ShaderMaterial({
      uniforms: {
        uTexture1: {
          value: texture
        },
        uTime: {
          value: 0
        },
        noteStrength: {
          value: 0
        },
        timeFactor: {
          value: 10
        },
        scaleFactor: {
          value: 0.5
        },
        noteHeight: {
          value: 0.01
        },
        rippleHeight: {
          value: 0.1
        },
        glowColor: {
          value: notesConfig.colors.perfect
        },
        duration: {
          value: 0.8
        }

      },
      vertexShader: glowNotesVertexShader,
      fragmentShader: glowNotesFragmentShader,
      transparent: true,
      side: THREE.DoubleSide,
      depthWrite: false,
      // blending: THREE.AdditiveBlending
    })

    this.leftNotesEffects.setUpNoteEffect(material.clone(), geometry, this.leftOuterNotes[0])
    this.rightNotesEffects.setUpNoteEffect(material, geometry, this.rightOuterNotes[0])

  }

  /**
   * Nastavenie labels
   */
  private prepareLabels(): void {

    const size = new THREE.Vector2()
    game.renderManager.renderer.getSize(size)
    const elementGame = document.getElementById('game-container')

    this.labelRenderer.setSize(
      elementGame?.clientWidth ?? size.x,
      elementGame?.clientHeight ?? size.y
    )
    this.labelRenderer.domElement.style.pointerEvents = 'none'
    this.labelRenderer.domElement.style.position = 'fixed'
    this.labelRenderer.domElement.style.top = '0px'
    this.labelRenderer.domElement.style.left = '0px'
    document.getElementById('main-ui')?.appendChild( this.labelRenderer.domElement )

    const labelSides = [Sides.LEFT, Sides.RIGHT]
    labelSides.forEach((side: Sides) => {

      notesConfig.labels.forEach((labelName) => {

        const divOuter = document.createElement('div')
        divOuter.className = 'label'
        const divInner = document.createElement('div')
        Object.assign(
          divInner.style,
          notesConfig.labelStyle[side]
        )
        divInner.style.backgroundImage =
      `url('https://appspowerplaymanager.vshcdn.net/images/summer-sports/minigame/kayak/ui/hlaska_${
        labelName
      }.png')`
        divInner.style.transform = `scale(${window.minigameScaleCoef})`
        divInner.style.marginLeft = `${notesConfig.labelStyle[side].marginLeft * window.minigameScaleCoef }%`
        divOuter.appendChild(divInner)

        const label = new CSS2DObject( divOuter )
        label.position.set( 0, 0, 0 )
        label.uuid = labelName
        label.visible = false

        if (side === Sides.RIGHT) {

          player.playerNotesManager.rightOuterNotes[0].add( label )
          this.rightLabels.push(label)

        } else {

          player.playerNotesManager.leftOuterNotes[0].add( label )
          this.leftLabels.push(label)

        }

      })

    })

  }

  private prepareStartArrow(): void {

    const divStartArrowR = document.createElement('div')
    divStartArrowR.className = 'label'
    divStartArrowR.id = 'start-arrow-teleport-right'
    const labelR = new CSS2DObject( divStartArrowR )
    labelR.position.set( 0, 0, 0 )
    const divStartArrowL = document.createElement('div')
    divStartArrowL.className = 'label'
    divStartArrowL.id = 'start-arrow-teleport-left'
    const labelL = new CSS2DObject( divStartArrowL )
    labelL.position.set( 0, 0, 0 )


    player.playerNotesManager.rightOuterNotes[0].add( labelR )
    player.playerNotesManager.leftOuterNotes[0].add( labelL )


  }

  /**
   * Nastavenie bottom hud div
   */
  private prepareBottomHud(): void {

    const divBottomHud = document.createElement('div')
    divBottomHud.className = 'label'
    divBottomHud.id = 'bottom-hud-teleport'
    const label = new CSS2DObject( divBottomHud )
    label.position.set( 0, 0, -2.5 )
    player.helperObject.add(label)

  }

  /**
   * Spravenie efektu po inpute na outer kruhoch
   * @param side - Strana
   */
  public afterInputEffect(side: Sides | undefined): void {

    if (side === undefined) return

    const objectToScale = side === Sides.LEFT ? this.leftOuterNotes[0] : this.rightOuterNotes[0]
    const scale = 1.1

    if (this.timelineOuterNotes) this.timelineOuterNotes.kill()
    objectToScale.scale.set(1, 1, 1)

    this.timelineOuterNotes = gsap.timeline()
      .to(
        objectToScale.scale,
        {
          x: scale,
          y: scale,
          z: scale,
          duration: 0.05,
          ease: 'power1.out'
        }
      ).to(
        objectToScale.scale,
        {
          x: 1,
          y: 1,
          z: 1,
          delay: 0.05,
          duration: 0.05,
          ease: 'power1.out'
        }
      )


  }

  /**
   * Update
   */
  public update(): void {

    if (!this.visible) return

    let leftAdder = 0
    let rightAdder = 0
    let leftColor = this.leftColor
    let rightColor = this.rightColor
    let leftColorOuter = notesConfig.colors.white
    let rightColorOuter = notesConfig.colors.white

    const percent = 100 / (player.velocityManager.paddleFrequency) * (player.velocityManager.intervalFrames + 1)
    let percent1 = percent
    let percent2 = percent
    let percent3 = percent
    let percent4 = percent
    const indexColor = player.velocityManager.qualityText as NoteColors
    const color = notesConfig.colors[indexColor] ?? notesConfig.colors.default

    if (player.velocityManager.activeSide === Sides.RIGHT) {

      if (disciplinePhasesManager.getActualPhase() !== DisciplinePhases.start) {

        rightColor = color
        rightColorOuter = color === notesConfig.colors.default ? notesConfig.colors.white : color
        if (gameSettingsState().graphicsSettings >= 5 && this.clock) {

          this.rightNotesEffects.checkVisibilityAndNoteEffect(color, this.clock.getElapsedTime())

        }

      } else {

        percent1 = -50

      }
      leftAdder = 100

    } else {

      if (disciplinePhasesManager.getActualPhase() !== DisciplinePhases.start) {

        leftColor = color
        leftColorOuter = color === notesConfig.colors.default ? notesConfig.colors.white : color
        if (gameSettingsState().graphicsSettings >= 5 && this.clock) {

          this.leftNotesEffects.checkVisibilityAndNoteEffect(color, this.clock.getElapsedTime())

        }

      } else {

        percent2 = -50

      }
      rightAdder = 100

    }


    percent1 += leftAdder
    percent2 += rightAdder
    percent3 += leftAdder
    percent4 += rightAdder

    const { lineOffset } = notesConfig
    const point1 = WorldEnvLinesCreator.getPointInBetweenByPerc(
      this.leftPoints[0],
      this.leftPoints[1],
      percent1 / 100
    )
    this.leftInnerNotes[0].position.z = lineOffset.z + point1.z
    this.leftInnerNotes[0].position.x = -lineOffset.x + point1.x
    const point2 = WorldEnvLinesCreator.getPointInBetweenByPerc(
      this.rightPoints[0],
      this.rightPoints[1],
      percent2 / 100
    )
    this.rightInnerNotes[0].position.z = lineOffset.z + point2.z
    this.rightInnerNotes[0].position.x = lineOffset.x + point2.x

    const point3 = WorldEnvLinesCreator.getPointInBetweenByPerc(
      this.leftPoints[0],
      this.leftPoints[1],
      (percent3 - 200) / 100
    )
    this.leftInnerNotes[1].position.z = lineOffset.z + point3.z
    this.leftInnerNotes[1].position.x = -lineOffset.x + point3.x
    const point4 = WorldEnvLinesCreator.getPointInBetweenByPerc(
      this.rightPoints[0],
      this.rightPoints[1],
      (percent4 - 200) / 100
    )
    this.rightInnerNotes[1].position.z = lineOffset.z + point4.z
    this.rightInnerNotes[1].position.x = lineOffset.x + point4.x

    if (this.leftColor !== leftColor) {

      this.leftLabels.forEach(label => label.visible = false)
      const leftLabel = this.leftLabels.find(label => label.uuid === player.velocityManager.qualityText)
      if (leftLabel) leftLabel.visible = true

      this.leftColor = leftColor
      const leftInnerNoteMaterial = this.leftInnerNotes[0].material as THREE.MeshBasicMaterial
      leftInnerNoteMaterial.color.set(leftColor)
      const leftOuterNoteMaterial = this.leftOuterNotes[0].material as THREE.MeshBasicMaterial
      leftOuterNoteMaterial.color.set(leftColorOuter)

      this.leftNotesEffects.update(leftColor)

    }
    if (this.rightColor !== rightColor) {


      this.rightLabels.forEach(label => label.visible = false)
      const rightLabel = this.rightLabels.find(label => label.uuid === player.velocityManager.qualityText)
      if (rightLabel) rightLabel.visible = true

      this.rightColor = rightColor
      const rightInnerNoteMaterial = this.rightInnerNotes[0].material as THREE.MeshBasicMaterial
      rightInnerNoteMaterial.color.set(rightColor)
      const rightOuterNoteMaterial = this.rightOuterNotes[0].material as THREE.MeshBasicMaterial
      rightOuterNoteMaterial.color.set(rightColorOuter)

      this.rightNotesEffects.update(rightColor)

    }

  }

  /**
   * Akutalizacia shaderov efektov
   * @param newValue - Hodnota casu
   */
  public updateEffects(newValue: number): void {

    const quality = gameSettingsState().graphicsSettings

    // pri kvalite 5+ davame efekt
    // TODO: dat cez konfig?
    if (quality >= 5) {

      this.leftNotesEffects.updateEffects(newValue)
      this.rightNotesEffects.updateEffects(newValue)

    }

  }

  /**
   * Renderovanie labelov
   */
  public renderLabels(): void {

    this.labelRenderer.render(game.scene, cameraManager.getMainCamera())

  }

  /**
   * Ukrytie vsetkych labels
   */
  public hideAllLabels(): void {

    this.labelRenderer.domElement.style.display = 'none'
    this.leftLabels.forEach(label => label.visible = false)
    this.rightLabels.forEach(label => label.visible = false)

  }

  /**
   * Nastavenie visibility pre vsetky objekty
   * @param visible - visibility
   * @param excludeInner - vynechat inner
   */
  public setVisibilityOfAll(visible: boolean, excludeInner = false): void {

    this.visible = visible
    if (!visible) {

      this.hideAllLabels()

    } else {

      this.labelRenderer.domElement.style.display = 'block'

    }
    this.leftOuterNotes.forEach(mesh => mesh.visible = visible)
    this.rightOuterNotes.forEach(mesh => mesh.visible = visible)
    if (!excludeInner) {

      this.leftInnerNotes.forEach(mesh => mesh.visible = visible)
      this.rightInnerNotes.forEach(mesh => mesh.visible = visible)

    }

    this.leftNotesEffects.setVisibilityLineEffect(visible)
    this.rightNotesEffects.setVisibilityLineEffect(visible)

  }

  /**
   * Nastavenie farby
   * @param color - nova farba
   */
  public setInnerColorOfStart(color: NoteColors): void {

    const colorObject = notesConfig.colors[color]
    if (player.athleteAnimationManager.animationSide === Sides.RIGHT) {

      this.rightColor = colorObject
      const rightInnerNoteMaterial = this.rightInnerNotes[0].material as THREE.MeshBasicMaterial
      rightInnerNoteMaterial.color.set(colorObject)

    } else {

      this.leftColor = colorObject
      const leftInnerNoteMaterial = this.leftInnerNotes[0].material as THREE.MeshBasicMaterial
      leftInnerNoteMaterial.color.set(colorObject)

    }

  }

  /**
   * Resetovanie veci
   */
  public reset(): void {

    this.leftColor = notesConfig.colors.default
    this.rightColor = notesConfig.colors.default

    const leftInnerNoteMaterial = this.leftInnerNotes[0].material as THREE.MeshBasicMaterial
    leftInnerNoteMaterial.color.set(notesConfig.colors.default)
    const leftOuterNoteMaterial = this.leftOuterNotes[0].material as THREE.MeshBasicMaterial
    leftOuterNoteMaterial.color.set(notesConfig.colors.white)
    const rightInnerNoteMaterial = this.rightInnerNotes[0].material as THREE.MeshBasicMaterial
    rightInnerNoteMaterial.color.set(notesConfig.colors.default)
    const rightOuterNoteMaterial = this.rightOuterNotes[0].material as THREE.MeshBasicMaterial
    rightOuterNoteMaterial.color.set(notesConfig.colors.white)

    this.leftNotesEffects.reset()
    this.rightNotesEffects.reset()

  }

}