import {
  CustomEvents,
  translations,
  CameraStates,
  game,
  modes,
  playersManager,
  trainingManager,
  hints,
  requestManager,
  audioManager,
  cameraManager,
  type PlayerInfo
} from '@powerplay/core-minigames'
import {
  AudioGroups,
  AudioNames
} from '@/app/types'
import {
  loadingCircleState,
  loadingState,
  translationsSSM as t,
  tutorialCoreState
} from '@powerplay/core-minigames-ui-ssm'
import { tutorialFlow } from '@/app/modes/tutorial/TutorialFlow'
import { opponentsManager } from '@/app/entities/athlete/opponent/OpponentsManager'
import { disciplinePhasesManager } from '@/app/phases/DisciplinePhasesManager'
import { player } from '@/app/entities/athlete/player'
import { translatesReplacements } from '@/app/config'
import {
  blurState,
  instructionsState,
  tableState,
  tournamentState,
  trainingResultsState,
  uiState
} from '@/stores'
import { stateManager } from '@/app/StateManager'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const initiateListeners = (vm: any) => {

  // Listener for language download
  window.addEventListener(CustomEvents.translations, () => {

    if (Number(import.meta.env.VITE_APP_LOCAL)) {

      translations.setTranslations(t, translatesReplacements)

    }

    vm.$i18n.setLocaleMessage('lang', translations.getTranslations())

    // musime zmenit hint v loadingu
    hints.setSpecificHintsCountForDiscipline(1)
    const hint = hints.getHintText(requestManager.disciplineID)

    loadingState().$patch({
      showLoading: true,
      hintText: vm.$t(hint)
    })

  })

  // Listener for game start event
  window.addEventListener(CustomEvents.gameStart, () => {

    if (!modes.isTutorial()) {

      loadingState().showLoading = false

    }

  })

  // Listener for game start instruction phase
  window.addEventListener(CustomEvents.startInstructionsPhase, () => {

    instructionsState().$patch({
      showInstructions: true,
      showButton: false
    })
    cameraManager.setState(CameraStates.table)
    cameraManager.playTween(true)
    game.renderScene()

  })

  // Listener pre tutorial Typewrite
  window.addEventListener(CustomEvents.typeWrite, () => {

    if (!modes.isTutorial()) return

    tutorialCoreState().typeWriter = false

  })

  // Listener pre tutorial game event
  window.addEventListener(CustomEvents.tutorialGameEvent, () => {

    if (!modes.isTutorial()) return

    tutorialFlow.checkInput()

  })

  // Listener for game intro start event
  window.addEventListener(CustomEvents.startIntroPhase, () => {

    audioManager.play(AudioNames.audienceNoise)
    if (modes.isTutorial()) return
    tableState().$patch({
      competitionText: modes.isTrainingMode() ?
        vm.$t('level').replace('%s', trainingManager.level) :
        `tableText${modes.mode}`,
      showTable: true
    })

    if (!modes.isTutorial() && !modes.isTrainingMode()) {

      audioManager.stopAudioByGroup(AudioGroups.commentators)
      audioManager.play(AudioNames.commentatorIntro)

    }

  })

  // Listener for game start listing phase
  window.addEventListener(CustomEvents.startStartListPhase, () => {

    if (modes.isTutorial()) return

    blurState().$patch({
      isActive: true,
      isTable: true
    })

    // kvoli dennej lige musime dat naspat originalnych superov, aby sa zobrazili v startovacej listine
    if (modes.isDailyLeague()) opponentsManager.setOriginalData()

    // nastavenie zoradenia hracov pre startovu listinu
    const players: PlayerInfo[] = []
    const isBossFight = modes.isEventBossFight()

    if (modes.isDailyLeague()) {

      // v dennej lige davame vsetkych hracov bez realnej pozicie
      players.push(...playersManager.players.map((val) => val))

    } else {

      const playerIndexBossFight = disciplinePhasesManager.dataForPlayersStartPositions[player.uuid] === 4 ? 0 : 1

      const pathIndexPlayer = player.worldEnvLinesManager.pathIndex
      const indexPlayer = isBossFight ? playerIndexBossFight : (pathIndexPlayer - 1)
      players[indexPlayer] = playersManager.getPlayer()
      players[indexPlayer].position = pathIndexPlayer

      opponentsManager.getOpponents().forEach((opponent) => {

        const pathIndexOpponent = opponent.worldEnvLinesManager.pathIndex
        const indexOpponent = isBossFight ? (1 - playerIndexBossFight) : (pathIndexOpponent - 1)
        players[indexOpponent] = playersManager.getPlayerById(opponent.uuid) as PlayerInfo
        players[indexOpponent].position = pathIndexOpponent

      })

    }

    const data = playersManager.getDataForTable(players)
    console.log(data)
    tableState().$patch({
      dataTable: data,
      resultText: 'startList',
      isStartList: true,
      activeState: true
    })

    // v dennej lige potom vratime fake hracov, aby sa ti zobrazovali
    if (modes.isDailyLeague() || modes.isTournament()) opponentsManager.setFakeData()

  })

  // Listener for game start discipline phase
  window.addEventListener(CustomEvents.startDisciplinePhase, () => {

    if (modes.isTutorial()) return

    blurState().$patch({
      isActive: false,
      isTable: false
    })

    tableState().$patch({
      showTable: false,
      activeState: false,
      isStartList: false
    })

  })

  // Listener for game start provisional results phase
  window.addEventListener(CustomEvents.startProvisionalResultsPhase, () => {

    if (modes.isTutorial()) return

    blurState().$patch({
      isActive: true,
      isTable: true
    })

    opponentsManager.setOriginalData()
    playersManager.setStandings(3, modes.isTournament(), modes.isTournament())

    const dataStandings = playersManager.getStandings()
    console.log(dataStandings)

    // musime si zaznacit najlepsi vysledok, aby sme to potom mohli zobrazit
    tournamentState().bestResult = dataStandings[0].result ?? '-'
    if (tournamentState().bestResult === '') tournamentState().bestResult = '-'

    tableState().$patch({
      resultText: 'provisionalResults',
      showTable: true,
      activeState: true,
      dataTable: dataStandings
    })

    opponentsManager.setFakeData()
    // audioManager.stopAllAudio()

  })

  // Listener for game start final standings phase
  window.addEventListener(CustomEvents.startFinalStandingsPhase, () => {

    if (modes.isTutorial()) return

    if (!audioManager.isAudioGroupPlaying(AudioGroups.commentators)) {

      audioManager.play(AudioNames.commentatorFinalResults)

    }

    uiState().showFinishTopBox = false

    if (modes.isTournament()) return

    blurState().$patch({
      isActive: true,
      isTable: true
    })

    tableState().$patch({
      competitionText: modes.isTrainingMode() ?
        vm.$t('level').replace('%s', trainingManager.level) :
        `tableText${modes.mode}`,
      dataTable: playersManager.getStandings(),
      resultText: modes.isTournament() || modes.isDailyLeague() ? 'provisionalResults' : 'finalStandings',
      showTable: true,
      activeState: true
    })

  })

  // Listener for game start final training phase
  window.addEventListener(CustomEvents.startFinalTrainingPhase, () => {

    if (modes.isTutorial()) return
    trainingResultsState().showResults = true

    // audioManager.stopAllAudio()
    // audioManager.play(AudioNames.audienceNoise, undefined, undefined, 1)

  })

  // Listener pre to, aby sme vedeli, ze su vsetky konecne requesty zbehnute
  window.addEventListener(CustomEvents.allRequestDoneOnEnd, () => {

    if (modes.isTournament() && Number(import.meta.env.VITE_APP_LOCAL) === 1) return

    stateManager.afterAllRequestsDone()

  })

  /** Listener pre zobrazenie loading circle po kliknuti na final redirect */
  window.addEventListener(CustomEvents.finalRedirectDone, () => {

    console.log('mal by ist redirect loading')
    loadingCircleState().isActive = true

  })

}
