import { flagsConfig } from '@/app/config/flagsConfig'
import {
  fpsManager,
  game,
  THREE
} from '@powerplay/core-minigames'
import { player } from '../athlete/player'
import { pathsConfig } from '@/app/config'
import { Skybox } from './Skybox'
import { Water } from './Water'

/**
 * Trieda pre prostredie
 */
export class WorldEnv {

  /** options pre meshe vlajok */
  private flagMeshesOptions: {mesh: THREE.Mesh, indexFrom: number, indexTo: number, actualRotation: number}[] = []

  /** progress vlnenia vlajok */
  private progress = 0

  /** Lightmapa vlajok pre hybanie */
  private flagsLightMap!: THREE.Texture | null

  /** Mesh pre terc ako shadow plane */
  public targetBgMesh!: THREE.Mesh

  /** Ci je povolene hybanie vlajkami */
  public movingFlagsEnabled = true

  /** Ci je aktivne zaklopenie zarazok na starte */
  public startlineBlockersActive = false

  /** Ciara v cieli */
  private finishLine!: THREE.Mesh

  /** Zarazky na starte */
  private startlineBlockers!: THREE.Object3D

  /** Originalna rotacia pre startovacie zarazky na x osi */
  private startLineBlockersOrigRotationX = 0

  /** Msh vzducholode */
  private blimpMesh!: THREE.Mesh

  /** Smerovy vector pre vzducholod */
  private blimpDir = new THREE.Vector3()

  /** Mesh pre bojky */
  private buoysMesh!: THREE.Mesh

  /** Aktualny smer pohybu bojok */
  private buoysMovementDir = 1

  /** Aktualny offset pre bojky */
  private buoysActualOffset = 0

  /** Mesh pre bojky na starte */
  private buoysMeshStart!: THREE.Mesh

  /** Aktualne maximum pre pohyb bojok */
  private buoysActualMax = 0.03

  /** obloha */
  public skybox = new Skybox()

  /** voda */
  public water = new Water()

  /**
   * Vytvorenie prostredia
   * @param clock - Hodiny
   */
  public create(clock: THREE.Clock): void {

    console.log('VYTVARAM ENV....')
    this.skybox.create()
    this.water.create(clock)
    player.playerNotesManager.setClock(clock)
    this.createFlags()

    // leader ciaru zatial nepotrebujeme
    const line = game.getMesh('env_LeadIndicator_Line')
    line.visible = false

    // cielova grafika
    this.finishLine = game.getMesh('env_FinishLine_K1')
    this.finishLine.position.y = 2.735
    this.finishLine.updateMatrix()
    this.finishLine.renderOrder = 5
    this.setVisibilityFinishLine(false)

    // pre reklamy staci dat front side
    const adsMaterial = game.getMesh('Env_K1_Mesh003_5').material as THREE.MeshBasicMaterial
    adsMaterial.side = THREE.FrontSide

    this.startlineBlockers = game.getObject3D('StartLine_Blockers')
    this.startlineBlockers.matrixAutoUpdate = true
    this.startLineBlockersOrigRotationX = this.startlineBlockers.rotation.x

    this.blimpMesh = game.getMesh('env_Blimp')
    this.blimpMesh.matrixAutoUpdate = true
    this.blimpDir = this.blimpMesh.getWorldDirection(new THREE.Vector3()).multiplyScalar(0.3)

    this.buoysMesh = game.getMesh('env_Buoys_K1')
    this.buoysMesh.matrixAutoUpdate = true

    this.buoysMeshStart = game.getMesh('StartLine_Mesh004_2')
    this.buoysMeshStart.matrixAutoUpdate = true

    // schovame co netreba
    game.getMesh('Env_K1_Mesh003').visible = false
    game.getObject3D('K1_Track_9').visible = false

    console.log('ENV vytvoreny....')

  }

  /**
   * Vytvorenie vlajok
   */
  private createFlags(): void {

    const mesh = game.getMesh('flags_Flag_Base')
    const material = mesh.material as THREE.MeshBasicMaterial
    this.flagsLightMap = material.lightMap

    for (let i = 1; i <= 10; i += 1) {

      const clonedMesh = mesh.clone()
      clonedMesh.geometry = mesh.geometry.clone()
      clonedMesh.matrixAutoUpdate = true
      clonedMesh.name = `${mesh.name}_cloned_${i}`
      const stringNumber = i < 10 ? `0${i}` : i
      const object3D = game.getObject3D(`Flag_0${stringNumber}`)
      object3D.scale.set(1, 1, 1)
      object3D.add(clonedMesh)
      clonedMesh.rotation.x = -Math.PI / 2
      clonedMesh.rotation.y = 0
      const random = THREE.MathUtils.randInt(0, 8)
      this.flagMeshesOptions.push({
        mesh: clonedMesh,
        indexFrom: random,
        indexTo: random + 1,
        actualRotation: 1
      })

      // musime dat na nulu prvy influence, lebo nam to bude robit potom problemy
      if (clonedMesh.morphTargetInfluences) clonedMesh.morphTargetInfluences[0] = 0

      this.changeUVs(
        clonedMesh,
        flagsConfig.flagsToUse[i - 1].x,
        flagsConfig.flagsToUse[i - 1].y
      )

    }

    mesh.visible = false

  }

  /**
   * Aktualizovanie veci spolu s animaciami, teda na FULL FPS
   * @param elapsedTime - Kolko casu uplynulo, kvoli shaderom
   */
  public updateWithAnimations(elapsedTime: number): void {

    this.skybox.update(elapsedTime)
    this.water.update(elapsedTime)
    player.playerNotesManager.updateEffects(elapsedTime)

  }

  /**
   * Aktualizovanie veci v normalnom internom FPS
   */
  public update(): void {

    this.updateFlags()
    this.updateStartlineBlockers()
    this.manageBlimp()
    this.manageBuoys()

  }

  /**
   * Nastavenie viditelnosti pre cielovu stenu
   * @param visibility - Viditelnost
   */
  public setVisibilityFinishLine(visibility: boolean): void {

    this.finishLine.visible = visibility

  }

  /**
   * Zmena UV
   * @param mesh - Mesh na ktorom menime UVcka
   * @param indexU - index pre u koordinat
   * @param indexV - index pre v koordinat
   */
  private changeUVs(mesh: THREE.Mesh, indexU: number, indexV: number): void {

    const uvAttribute = mesh.geometry.attributes.uv
    for (let i = 0; i < uvAttribute.count; i++) {

      const u = uvAttribute.getX(i) + (flagsConfig.horizontalValue * indexU)
      const v = uvAttribute.getY(i) + (flagsConfig.verticalValue * indexV)

      uvAttribute.setXY(i, u, v)

    }

    uvAttribute.needsUpdate = true

  }

  /**
   * Hybanie vlajkami
   */
  private waveFlags(): void {

    let nullifyProgress = false
    this.flagMeshesOptions.forEach(({ mesh, indexFrom, indexTo, actualRotation }, index) => {

      if (!mesh.morphTargetInfluences) return

      mesh.morphTargetInfluences[indexFrom] = 1 - this.progress
      mesh.morphTargetInfluences[indexTo] = this.progress

      mesh.rotateY(this.flagMeshesOptions[index].actualRotation > 0 ?
        flagsConfig.rotationSpeed :
        -flagsConfig.rotationSpeed)


      if (this.progress >= 1) {

        mesh.morphTargetInfluences[indexFrom] = 0
        mesh.morphTargetInfluences[indexTo] = 1

        this.flagMeshesOptions[index].indexFrom += 1
        if (this.flagMeshesOptions[index].indexFrom >= 10) this.flagMeshesOptions[index].indexFrom = 0

        this.flagMeshesOptions[index].indexTo += 1
        if (this.flagMeshesOptions[index].indexTo >= 10) this.flagMeshesOptions[index].indexTo = 0


        const changeDir = Math.random() > flagsConfig.rotationChangeProbability

        if (changeDir || mesh.rotation.y > flagsConfig.rotationMax || mesh.rotation.y < flagsConfig.rotationMin) {

          this.flagMeshesOptions[index].actualRotation = actualRotation * -1

        }

        nullifyProgress = true

      }

    })

    if (nullifyProgress) this.progress = 0

    this.progress += (flagsConfig.updateSpeed / fpsManager.fpsLimit)

  }

  /**
   * Aktualizacia vlajok
   */
  public updateFlags(): void {

    if (!this.movingFlagsEnabled) return

    this.waveFlags()

    if (this.flagsLightMap) this.flagsLightMap.offset.x -= flagsConfig.lightMapSpeed

  }

  /**
   * Zaklopenie startovacich zarazok
   */
  public updateStartlineBlockers(): void {

    if (!this.startlineBlockersActive) return

    this.startlineBlockers.rotation.x += 0.1

    if (this.startlineBlockers.rotation.x > 1) {

      this.startlineBlockersActive = false

    }

  }

  /**
   * Resetovanie startovacich zarazok
   */
  public resetStartlineBlockers(): void {

    this.startlineBlockersActive = false
    this.startlineBlockers.rotation.x = this.startLineBlockersOrigRotationX

  }

  /**
   * Posuvanie vzducholode
   */
  public manageBlimp(): void {

    this.blimpMesh.position.sub(this.blimpDir)

  }

  /**
   * Pohyb bojok
   */
  public manageBuoys(): void {

    const addValue = this.buoysMovementDir * 0.0025
    const moveStartBuoys = player.getActualPercentOnPath() <= pathsConfig.positionsStart[0] + 5

    this.buoysActualOffset += addValue
    this.buoysMesh.position.y += addValue
    if (moveStartBuoys) this.buoysMeshStart.position.y += addValue

    if (
      (this.buoysMovementDir === 1 && this.buoysActualOffset >= this.buoysActualMax) ||
      (this.buoysMovementDir === -1 && this.buoysActualOffset <= -this.buoysActualMax)
    ) {

      this.buoysMovementDir *= -1
      this.buoysActualOffset -= addValue
      this.buoysMesh.position.y -= addValue
      if (moveStartBuoys) this.buoysMeshStart.position.y -= addValue

      this.buoysActualMax = THREE.MathUtils.randFloat(0.02, 0.04)

    }

  }

}

export const worldEnv = new WorldEnv()
